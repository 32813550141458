import React, { FC, useState, useEffect } from 'react';
import { PageProps, navigate } from 'gatsby';
import { MainLayout } from '../../templates/MainLayout';
import { t, DEFAULT_LOCALE } from '../../i18n';
import { usePathPrefix } from '../../constants/hooks';
import styles from './styles.module.scss';
import { Button } from '../../components/main/Button';

// eslint-disable-next-line @typescript-eslint/ban-types
type IProps = PageProps<{}, { locale: string }>;

const NotFoundPage: FC<IProps> = ({
  pageContext: { locale = 'en' },
  location,
}) => {
  const prefix = usePathPrefix();

  const [loading, setLoading] = useState(true);
  const redirectUrl = process.env.GATSBY_REDIRECT_URL;
  const path = location.pathname.replace('/ru', '');
  const url = `${redirectUrl}${path}`;

  const makeRequest = async () => {
    setLoading(true);
    await fetch(url, {
      method: 'HEAD',
      cache: 'no-cache',
    })
      .then((res) => {
        if (res.status === 200) {
          document.location.replace(url);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return null;
      });
  };

  useEffect(() => {
    makeRequest();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <MainLayout title="title.not_found" locale={locale}>
      <form
        className={styles.wrap}
        onSubmit={(e) => {
          e.preventDefault();
          navigate(
            locale === DEFAULT_LOCALE ? prefix : `/${prefix}/${locale}/`
          );
        }}
      >
        <div
          className={styles.image}
          style={{ backgroundImage: `url('${prefix}/404.svg')` }}
        >
          <svg viewBox="0 0 624 662" />

          <div className={styles.content}>
            <div className={styles.title}>{t('main.not_found')}</div>
            <div className={styles.subtitle}>{t('main.page_missing')}</div>

            <div className={styles.button}>
              <Button>{t('main.back_to_home')}</Button>
            </div>
          </div>
        </div>
      </form>
    </MainLayout>
  );
};

export default NotFoundPage;
